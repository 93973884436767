.gallery {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	width: 100%;
	color: white;
	padding: 5% 10%;
	gap: 3rem;
}

.locked {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	gap: 2rem;
	text-align: center;
}

p.warning {
	font-size: 1.2rem;
	color: #ff7979;
}

.passwordInput {
    width: 100%;
    max-width: 15rem;
    padding: 0.5rem 1rem;
    border-radius: 12px;
    border: none;
    outline: none;
    font-size: 1rem;
    background: #2f3136;
    color: white;
    margin-bottom: 1rem;
}

.passwordSubmit {
    width: 100%;
    max-width: 15rem;
    padding: 0.5rem 1rem;
    border-radius: 12px;
    border: none;
    outline: none;
    font-size: 1rem;
    background: #fe7878;
    color: white;
    cursor: pointer;
    transition: background-color 0.5s;
}

.passwordSubmit:hover {
    background: #be5959;
}

.galleryGrid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	grid-auto-rows: 1fr;
	grid-gap: 1rem;
	width: 100%;
}

.closeContainer {
	position: absolute;
	top: 0;
	right: 0;
	padding: 1rem;
	z-index: 1;
}

.lightbox {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 0;
	position: absolute !important;
}

.spotifyContainer {
	display: flex;
	flex-wrap: wrap;
	gap: 2rem;
	width: 100%;
	justify-content: space-between;
}

.spotifyDesc {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	margin-top: 2rem;
}

.spotifyDesc > p {
	font-size: 1.2rem;
	max-width: 25rem;
}

.spotify {
	flex-grow: 1;
}

.spotifyIframe {
	border-radius: 12px;
	border: none;
}
