.infoCard1,
.infoCard2,
.infoCardResult,
.imageCard1 {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 1rem;
	text-align: center;
	border-radius: 10px;
    /* dark gray gradient */
    background: #2f3136;
	margin-left: 3%;
	margin-top: 3%;
}

.robotIntro {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2rem;
    text-align: center;
}

.infoCard1, .infoCard2, .infoCardResult {
    padding: 0 1rem;
}

.title {
	font-size: 1.5rem;
	text-align: center;
	overflow-wrap: inherit;
	margin-top: 2rem;
}

.scroll {
	position: absolute;
	top: 80%;
	width: 34px;
	height: 55px;
	margin: 20px;
}

.mouse {
	width: 3px;
	padding: 10px 15px;
	height: 35px;
	border: 2px solid #fff;
	border-radius: 25px;
	opacity: 0.75;
	box-sizing: content-box;
}

.scroller {
	width: 3px;
	height: 10px;
	border-radius: 25%;
	background-color: #fff;
	animation-name: scroll;
	animation-duration: 2s;
	animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
	animation-iteration-count: infinite;
}

@keyframes scroll {
	10% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(15px);
	}
}

.robotPart {
	position: fixed;
}

.silhouette {
	justify-content: center;
}
