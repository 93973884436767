.vanier {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	color: white;
	padding: 5% 10%;
	gap: 1rem;
}

/* Images should stay big and centered despite viewport, text too */
.vanier img {
	width: 80%;
	border-radius: 1%;
	display: flex;
}

.vanier .imgLogo {
	width: 50%;
	border-radius: 1%;
	display: flex;
}
