.systemIcon {
	width: 100px;
	background-color: transparent;
	display: flex;
	flex-direction: column;
	align-items: center;
	user-select: none;
	cursor: pointer;
}

.systemIcon div {
	width: 50px;
	aspect-ratio: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 3px;
	margin-bottom: 2px;
}

.systemIcon img {
	max-width: 50px;
	max-height: 50px;
	border-radius: 25%;
}

.systemIcon svg {
	width: 32px;
	height: 32px;
}

.systemIcon p {
	font-size: 12px;
	text-align: center;
	margin: 0;
	border-radius: 3px;
	padding: 0 5px;
}

.systemIcon:hover p,
.systemIcon:hover div {
	background: #414244;
}
