.blogPostContainer {
	display: flex;
	justify-content: center;
	align-items: center;
}

.blogPostContainer:hover {
	cursor: pointer;
}
.blogPostContainer:hover .circleLineAnim {
	animation-name: rotate;
	animation-duration: 2s;
	animation-iteration-count: infinite;
}
.blogPostContainer:hover .circleAnim {
	animation-name: glow;
	animation-duration: 2s;
	animation-iteration-count: infinite;
}

.blogPostContainer:hover .blogBox {
	filter: saturate(500);
	-webkit-filter: saturate(500);
}

@keyframes rotate {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(480deg);
	}
}

@keyframes glow {
	to {
		box-shadow: 0 0 24px 12px #fff, /* inner white */ 0 0 40px 24px #f05555, /* middle #99070c */ 0 0 56px 36px #99070c; /* outer cyan */
	}
}

.post {
	position: relative;
	overflow-wrap: break-word;
	width: 350px;
	min-width: 350px;
}

.blogBoxFX {
	position: absolute;
	top: 0;
	right: 0;
}

.blogBoxFXInner {
	position: relative;
	width: 6rem;
}

.circleAnim {
	scale: 30%;
	border-radius: 50%;
}

.circleLineAnim {
	scale: 60%;
}

.textPost {
	position: absolute;
	overflow-wrap: break-word;
	max-width: 220px;
	top: 25px;
	left: 38px;
}

.textPost h3 {
	font-size: 1.25rem;
}

.textPost p {
	font-size: 12px;
	padding-left: 1rem;
}

.blogId {
	color: #f05555;
	font-size: 1.25rem;
	font-weight: 900;
}

.blogBox {
	transition: filter 0.5s;
	-webkit-transition: -webkit-filter 0.5s linear;
}
