.album {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-color: rgba(255, 255, 255, 0.352);
	box-shadow: inset 0 0 0 2000px rgba(28, 28, 28, 0.27);
	border-radius: 0.5rem;
	transition: all 0.2s ease-in-out;
	cursor: pointer;
    height: 20rem;
    overflow: hidden;
}

.album:hover {
	transform: scale(1.05);
	box-shadow: none;
}

.albumImg {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 0.5rem;
}

.albumTitle {
	font-size: 1.5rem;
	font-weight: 600;
	width: 100%;
	padding: 0 1rem;
    padding-bottom: 1rem;
	background-color: rgba(0, 0, 0, 0.5);
}

.albumDescription {
	font-size: 1rem;
	font-weight: 300;
	margin-top: 0.5rem;
}

.albumTitle p {
    margin-bottom: 0;
}

.albumTitle small {
    font-size: 0.95rem;
    font-weight: 300;
    margin-bottom: 2em;
}