.acknow {
	display: flex;
	flex-direction: column;
	justify-content: center;
    align-items: center;
	width: 100%;
	color: white;
	padding: 5% 10%;
	gap: 1rem;
}

.img {
	object-fit: contain;
	width: 100%;
	margin-bottom: 2rem;
}