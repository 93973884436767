.closeButton {
	width: 50px;
	height: 50px;
	cursor: pointer;
	opacity: 80%;
	position: relative;
    display: flex;
    justify-content: flex-end;
}

.closeButton:hover {
	opacity: 100%;
}

.firstLine {
	height: 4px;
	width: 20px;
	position: absolute;
	margin-top: 24px;
	background-color: #d2d2d2;
	border-radius: 2px;
	transform: rotate(45deg);
	transition: all 0.3s ease-in;
}

.secondLine {
	height: 4px;
	width: 20px;
	position: absolute;
	margin-top: 24px;
	background-color: #d2d2d2;
	border-radius: 2px;
	transform: rotate(315deg);
	transition: all 0.6s ease-in;
}

.closeButton:hover .firstLine {
	transform: rotate(-45deg);
}

.closeButton:hover .secondLine {
	transform: rotate(45deg);
}
