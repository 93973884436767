body {
	padding: 0;
	margin: 0;
	transition: background-color 1s;
	color: #fff;
}

.corner {
	position: fixed;
	bottom: 0;
	right: 0;
	padding: 1rem;
	background: #c42020;
	color: #fff;
	font-size: 1rem;
	font-weight: 700;
	text-transform: uppercase;
	border-radius: 8px 0 0 0;
	user-select: none;
	cursor: pointer;
}

.container {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	width: 100%;
	height: 100vh;
	gap: 5rem;
	flex-wrap: wrap;
}

.section {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	flex-basis: 30%;
}

.element_visible {
	transition: opacity 2.5s;
	opacity: 1;
}

.element_hidden {
	transition: opacity 0.5s;
	opacity: 0;
}

.canvas_visible {
	transition: opacity 2.5s;
	opacity: 0.5;
}

.canvas_hidden {
	transition: opacity 0.5s;
	opacity: 0;
}

.home_title {
	font-size: 70pt;
	font-weight: 800;
	line-height: 80pt;
	margin: 0;
}

.home_subtitle {
	font-size: 30pt;
	font-weight: 800;
	text-transform: uppercase;
	color: #f05555;
	margin: 0;
}

.home_paragraph {
	font-size: 15pt;
	font-weight: 300;
}

.card {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	background-color: #f05555;
	border-radius: 8px;
	padding: 2rem;
	gap: 5rem;
	min-width: 20rem;
}

.user {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.userPic {
	width: 10rem;
	aspect-ratio: 1;
	border-radius: 50%;
	border: 5px solid #99070c;
}

.username {
	font-size: 1.5rem;
	margin: 0;
	margin-top: 10px;
	font-weight: 700;
}

.button_started {
	color: white;
	border-radius: 8px;
	font-weight: 700;
	font-size: 1.25rem;
	padding: 1rem 2rem;
	font-family: "Poppins", sans-serif;
	background-image: linear-gradient(to right, #c42020 0%, #99070c 100%);
	box-shadow: 0 0 20px #00000042;
	text-transform: uppercase;
	transition: 0.5s;
	position: relative;
	overflow: hidden;
	cursor: pointer;
}

.button_started > svg {
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
}

.button_started::before,
.button_started::after {
	animation: reflect 7s 0s infinite linear forwards;
}

.button_started::before {
	content: "";
	display: block;
	position: absolute;
	background: rgba(255, 255, 255, 0.5);
	width: 60px;
	height: 100%;
	top: 0;
	filter: blur(30px);
}

.button_started::after {
	content: "";
	display: block;
	position: absolute;
	background: rgba(255, 255, 255, 0.2);
	width: 30px;
	height: 100%;
	top: 0;
	filter: blur(5px);
}

.button_started:hover {
	transform: scale(1.1);
}

@keyframes reflect {
	0% {
		transform: translateX(-100px) skewX(-15deg);
		opacity: 0;
	}
	1%{
		opacity: 1;
	}
	11% {
		transform: translateX(300px) skewX(-15deg);
		opacity: 0;
	}
	100% {
		transform: translateX(300px) skewX(-15deg);
		opacity: 0;
	}
}

/* place a small edge on the bottom right of the screen */
.triangle {
	width: 0;
	height: 0;
	bottom: 0;
	right: 0;
	border-left: 150vw solid transparent;
	border-bottom: 20vh solid #f05555;
	position: absolute;
	z-index: -1;
}

/* Adjust the triangle's height depending on the width */
@media only screen and (max-width: 400px) {
	.triangle {
		border-bottom: 0vh solid #f05555;
	}
}

/* @media only screen and (min-width: 800px) {
	.section {
		padding: 5rem 0 0 7rem;
	}

	.home_title {
		font-size: 80pt;
		text-align: left;
	}

	.home_subtitle {
		font-size: 40pt;
		top: -30pt;
		text-align: left;
	}

	.home_paragraph {
		font-size: 15pt;
		width: 30rem;
		margin-left: 0;
		text-align: left;
	}

	.button_container {
		width: 20rem;
		margin-left: 0;
	}

	.button_started {
		font-size: 20pt;
		padding: 10px 40px;
		width: 100%;
	}

	.triangle {
		border-bottom: 20vh solid #f05555;
	}
} */

@media only screen and (min-width: 1000px) {
	.triangle {
		border-bottom: 40vh solid #f05555;
	}
}
