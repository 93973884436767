.bg {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background-size: cover;
	background-color: #f05555;
	color: #fff;
	height: 100vh;
	width: 100vw;
}

.user {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.user img {
	width: 150px;
	height: 150px;
	border-radius: 50%;
	border: 2px solid #fff;
}

.user h1 {
	font-size: 2rem;
	margin: 0;
	margin-top: 10px;
}

.loading {
	font-size: 1.5rem;
	margin-top: 10px;
}

.loading:after {
	overflow: hidden;
	display: inline-block;
	vertical-align: bottom;
	animation: ellipsis steps(4, end) 900ms infinite;
	content: "\2026";
	/* ascii code for the ellipsis character */
	width: 0px;
}

@keyframes ellipsis {
	to {
		width: 20px;
	}
}
