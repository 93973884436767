.container {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;
	justify-content: center;
	gap: 50px;
	padding: 50px 20px 20px 20px;
	width: 100%;
	height: 100%;
}

.blogListContainer {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
	grid-template-rows: repeat(1, 1fr);
	grid-gap: 20px;
	width: 100%;
	height: 100%;
	padding-right: 30px;
	border-color: white;
}

.blog-window {
	flex: 2 2 20rem;
}

.progress-img {
	position: absolute;
	left: -660px;
	top: -600px;
	scale: 10%;
	animation: translateY 10s cubic-bezier(0.5, 0.5, 0.5, 0.5) infinite;
}

@keyframes translateY {
	to {
		transform: translateY(1000px);
	}
}

.progress-img2 {
	position: absolute;
	right: -700px;
	top: -1050px;
	scale: 10%;
	rotate: -90deg;
	animation: translateX 10s backwards infinite;
}

@keyframes translateX {
	to {
		transform: rotate(360deg);
	}
}
