.note {
	position: absolute;
	max-width: 25rem;
	height: 6rem;
	bottom: 5rem;
	border-radius: 10px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	overflow: hidden;
	display: flex;
	flex-direction: row;
	align-items: center;
	pointer-events: auto;
	user-select: none;
	margin: 1rem;
	background: #272729;
	border: 1px solid rgba(225, 225, 225, 0.776);
	padding: 0.2rem 0.8rem;
	cursor: pointer;
}

.noteContent {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.noteLogo {
	height: 60%;
	margin-right: 0.8rem;
}

.noteTitle {
	font-size: 0.9rem;
	font-weight: 600;
    margin-bottom: 0.25rem;
}

.noteContent {
	font-size: 0.8rem;
	font-weight: 400;
    line-height: 1rem;
}
