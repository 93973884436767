.blogWindowContainer {
	display: flex;
	flex-direction: column;
	z-index: 2;
	height: 100%;
	width: 100%;
	padding: 1rem;
	align-items: center;
	justify-content: center;
}

.blogCarousel {
	display: flex;
	flex-direction: row;
	align-items: center;
	position: relative;
	width: 100%;
	height: 100%;
	flex-grow: 1;
	overflow-x: clip;
}

.carouselSlider {
	display: flex;
	justify-content: center;
	flex-grow: 1;
	height: 100%;
}

.carousel__slide {
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
}

.carouselSlider > div {
	width: 100%;
}

.blogWindowContent {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	gap: 1rem;
}

.blogWindowHeader {
	display: flex;
	padding: 0 2rem;
	justify-content: space-between;
}

.blogText {
	color: white;
	font-size: 17px;
	margin: none;
	padding: 0 2rem;
	padding-bottom: 2rem;
}

.blogTitle {
	font-size: 2rem;
	font-weight: 700;
	margin: 0;
}

.blogPostAsset {
	width: 80%;
	margin: auto;
	max-height: 30rem;
	object-fit: contain;
}

.arrowBtn {
	background: none;
	border: none;
	cursor: pointer;
	position: absolute;
	z-index: 1;
}

.arrowBtn:disabled {
	opacity: 0.5;
	cursor: unset;
}

.arrowRight {
	right: 0;
}
