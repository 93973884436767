.desktop {
	width: 100%;
	flex-grow: 1;
	padding: 10px;
	display: inline-flex;
	writing-mode: vertical-lr;
	flex-wrap: wrap;
	align-content: flex-start;
	gap: 20px;
	background-color: #1e1f21;
	color: #ffffff;
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
}

.desktopContainer {
	display: flex;
	height: 100vh;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	position: relative;
}

.desktopItem {
	writing-mode: horizontal-tb;
}

html {
	background-color: #1e1f21;
}

.sponsors {
	position: absolute;
	bottom: 0;
	width: 8rem;
	right: 0;
	padding-bottom: 5rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.sponsors > span {
	text-align: center;
	font-size: 0.8rem;
}

.sponsors > img {
	width: 100%;
	object-fit: contain;
}
