.team {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: #fff;
}

.teamRow {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 2rem;
	gap: 2rem;
	border: 1px solid #938888;
}

.teamText,
.teamCaptain {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-basis: 48%;
	height: 100%;
	flex-grow: 1;
}

.teamText strong {
	color: #ffa9a9;
}

.mentorsText {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-basis: 40%;
	height: 100%;
	flex-grow: 1;
}

.mentors {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: center;
	flex-basis: 60%;
	height: 100%;
	flex-grow: 1;
	flex-wrap: wrap;
}

.teamText {
	color: #fff;
	font-size: 1.25rem;
	gap: 0.25rem;
}

.teamTextBlock {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
}

/* .teamTextBlock h3 {
	margin: 0;
} */

.teamTextBlock p {
	font-size: 1rem;
}

.captainTitle {
	color: #fff;
	font-size: 1.5rem;
	flex-shrink: 0;
	margin: 0;
	z-index: 1;
}

.mentors .captainImage {
	max-width: 20rem;
}

.teamCaptainImage {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
}

.captainImage {
	max-width: 20rem;
	opacity: 1;
	filter: drop-shadow(20px 0 80px rgba(194, 75, 75, 0.556));
}

.captainImageHover {
	position: absolute;
	opacity: 0;
	width: 100%;
	height: 100%;
	transition: all 0.5s;
	transform: scale(1);
	filter: brightness(1);
}

.teamCaptainImage:hover .captainImageHover {
	filter: drop-shadow(20px 0 80px rgba(194, 75, 75, 0.556)) brightness(1.2);
	transform: scale(1.1);
	opacity: 1;
}

.teamCaptainImage:hover .captainImage {
	filter: drop-shadow(20px 0 80px rgba(194, 75, 75, 0.556));
	opacity: 0;
}

.teamRowTitle {
	color: #fff;
	font-size: 2rem;
	flex-shrink: 0;
}

.membersCarousel {
	display: flex;
	flex-grow: 1;
	position: relative;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.arrowBtn {
	background: none;
	border: none;
	position: absolute;
	cursor: pointer;
	z-index: 1;
}

.arrowBtn:disabled {
	opacity: 0.5;
	cursor: unset;
}

.arrowRight {
	right: 0;
}

.memberDesc {
	padding: 2rem;
	border: 0.15rem solid #938888;
	background-color: rgb(147, 136, 136, 0.22);
	width: 20rem;
	flex-shrink: 0;
	aspect-ratio: 1;
}

.titleText {
	color: white;
	font-size: 1rem;
}

.description {
	color: white;
	font-size: 0.75rem;
}

.tech2Container {
	position: relative;
	left: 85vw;
	bottom: 20vh;
	display: flex;
	scale: 150%;
}

.tech1Container {
	position: relative;
	right: 6.3vw;
	bottom: 50vh;
	display: flex;
	scale: 100%;
}

.row {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-grow: 1;
	gap: 2rem;
	flex-wrap: wrap-reverse;
}

.col {
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-grow: 1;
}

.member {
	display: flex;
	position: relative;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
	height: 100%;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center bottom;
}

.memberImage {
	width: 17rem;
	z-index: 1;
	border-radius: 1%;
	filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.556));
}

.memberOverlay {
	width: 50%;
	bottom: 0;
	position: absolute;
}

.memberName {
	margin: 0;
	position: absolute;
	bottom: 0;
	text-align: center;
	color: white;
	font-size: 1.25rem;
	z-index: 1;
}
