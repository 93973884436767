.crc {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	color: white;
	padding: 5% 10%;
	gap: 1rem;
}

.crc .row  {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 10%;
    padding-top: 1%;
    gap: 1rem;
    flex-wrap: wrap;
}

.crc img {
    width: 40%;
	min-width: 15rem;
	border-radius: 1%;
	display: flex;
}

