.taskbar {
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: 0.25rem 2%;
	background-color: #ffffff18;
	color: #fff;
}

.start {
	display: flex;
	align-items: center;
}

.start img {
	width: 45px;
	height: 45px;
	border-radius: 50%;
	border: 2px solid #fff;
}

.exit {
	display: flex;
	align-items: center;
	justify-content: center;
	user-select: none;
	cursor: pointer;
}

.language {
	display: flex;
	align-items: center;
	justify-content: center;
	user-select: none;
	cursor: pointer;
}

.subdiv {
	display: flex;
	gap: 1rem;
}

.time {
	display: flex;
	justify-content: center;
	width: 8rem;
}
