@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;700;800&display=swap");

body {
	margin: 0;
	font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

* {
	box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
}

a {
	text-decoration: none;
	color: inherit;
}

button {
	cursor: pointer;
	border: none;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.carousel__slide.carousel__slide--hidden {
	opacity: 0;
}
