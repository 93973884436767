.gameDesc {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	color: white;
	padding: 5% 10%;
	gap: 1rem;
}

.img {
	object-fit: contain;
	width: 100%;
	max-width: 30rem;
	margin-bottom: 2rem;
}
