.win {
	position: absolute;
	pointer-events: auto;
	border-radius: 10px;
	border: 1px solid #747676;
	background: #353738;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.winContent {
	background: #1d1f21;
	flex-grow: 1;
	position: relative;
	overflow-y: auto;
	overflow-x: hidden;
}

.winFrame {
	width: 100%;
	height: 100%;
	border: none;
}

.winHeader {
	padding: 2px 10px;
	border-bottom: 1px solid #000;
	color: #fff;
	height: 30px;
	display: flex;
	align-items: center;
}

.winHeader > div {
	display: flex;
	align-items: center;
}

.winHeader > div:first-of-type {
	font-size: 14px;
	user-select: none;
	max-width: 80%;
}

.winHeader > div:first-of-type img {
	max-height: 18px;
	width: auto;
	vertical-align: middle;
	margin-right: 5px;
	border-radius: 5px;
}

.winHeader > div:first-of-type * {
	font-size: 14px;
}

.winHeader > div:nth-of-type(2) {
	flex-grow: 1;
}

.winHeader > div:last-of-type {
	gap: 10px;
}

.winButton {
	border-radius: 100px;
	width: 16px;
	height: 16px;
	cursor: pointer;
	border-width: 1px;
	border-style: solid;
}

.winButton.close {
	color: #fa5e57;
	background: #fa5e57;
	border-color: #fa5e57;
}

.winButton.close:hover {
	color: #730a00;
	border-color: #e14039;
}

.winButton.fullscreen {
	color: #28c941;
	background: #28c941;
	border-color: #28c941;
}

.winButton.fullscreen:hover {
	color: #046201;
	border-color: #13aa27;
}
